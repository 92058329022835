import campaign from './campaigns/nebu'
import subscribe from './static-content/subscribe'
import indoorSolutions from './static-content/indoorSolutions'
import support from './static-content/support'
import altText from './static-content/altText'
import ira25C from './static-content/ira-25c'
import intelliHeat from './static-content/intelli-heat'
import download from './static-content/download'
import mitsubishiSystems from './static-content/mitsubishiSystems'

const buPhone = '855-897-9524'
const buHours = 'Mon-Fri, 8AM-5PM EST'

const hero = campaign()

const sections = [
    ira25C({ download: true, hasNavAnchor: true, color: 'orange' }),
    {
        id: 'how-heat-pumps-work',
        class: 'two-column-img-right',
        blurb: 'How Our Heat Pumps Heat AND Cool',
        detailsText:
            'Our all-climate heat pumps heat and cool any room, anywhere, in any season. Maybe you have a room that is unbearably cold during the winter, or maybe a space that requires you to drop the temperature of your entire house just to get that area comfortable during the summer. Your home should feel comfortable year-round.',
        imgSrc: '/images/how-heat-pumps-work-video-poster-summer.webp',
        imgAlt: `${altText} preview/poster for "how heat pumps work" video`,
        ctaText: 'Watch Video',
        ctaUrl: 'form-section',
        videoSrc: 'oETVJ7Nx9g0',
        backgroundCol: 'transparent',
    },
    {
        id: 'ductless',
        class: 'two-column-img-left',
        title: 'No Ducts?\nNo Problem',
        detailsText:
            "All-electric, all-climate heat pumps are designed for energy-efficiency and flexibility to solve your heating and cooling needs. Our systems are designed to fit any application, delivering personalized temperature control for one room or a whole home, with or without ducts. Read on to discover how to elevate your home's comfort.",
        imgSrc: '/images/nebu-spring-hero-img.webp',
        imgAlt: `${altText} happy family, man and woman in living room smiling and playing with child. to the right is an outdoor heat pump unit`,
        ctaText: 'Learn More',
        ctaUrl: 'form-section',
        backgroundCol: 'yellow',
    },
    indoorSolutions({ ctaText: 'Request a Quote', hasNavAnchor: true, products: ['wall-mounted', 'ceiling-cassette', 'intelli-heat', 'floor-mounted'] }),
    intelliHeat({}),
    mitsubishiSystems({}),
    subscribe({ campaignId: hero.subscriberCampaignId }),
    support({ phone: buPhone, hours: buHours }),
    download({}),
]

const header = {
    pageTitle: 'Save Big and Stay Cozy in the Northeastern Cold: Rebates & Winter Performance',
    pageDesc:
        "Stay comfortable year round with Mitsubishi Electric's single-zone and Hyper-Heating INVERTER® heat pump systems, where exceptional comfort meets energy efficiency. Enter your zip code now to unlock local incentives!",
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    hours: buHours,
    ctaText: 'Get Connected',
    // ctaUrl: 'subscriber-form',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
