import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '2/15/2023'
    const end = '5/31/2023'
    const amount = 0

    const data = {
        // defaults
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: defaults.subscriberCampaignId,
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/nebu-spring-hero-img.webp',
        defaultTitle: defaults.title,
        defaultBlurb: '',
        defaultDetails: defaults.detailsText,
        defaultFootnote: defaults.footnote,
        defaultWhatsNext: defaults.whatsNext,
        utilityProduct: defaults.utilityProduct,
        submitFormBtnText: null,
        // ME_Residential_SWBU_Spring_2023_Generator_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        campaignId: '7013q000002NkidAAC',
        staticCampaign: true,
        campaignHero: 'rebate',
        campaignCtaText: 'Get Started',
        campaignWhatsNext: '',
        campaignImage: '/images/hurricane-gulf.webp',
        campaignTitle: 'Be Proactive',
        campaignBlurb: '',
        campaignDetails:
            'Life can be unpredictable, so now is the time to ready your home for when extreme weather strikes. Mitsubishi Electric Heating and Air Conditioning high-efficiency heat pumps can be powered by a generator when the power is out.',
        campaignFootnote: '',
        hideContractorNumSelector: false,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}
