import Icon from './Icon'

export default function Button({ id, type, simpleLink, className, onClick, mini, text, iconId, href, center, color, disabled }) {
    const finalClassName = `${className && className} ${mini && 'mini'} ${simpleLink && 'simple-link'} ${!className && !simpleLink && 'btn'}`
    const btnText = text ? text : 'Learn More'
    const externalLink = href && href.includes('http')
    const hasIcon = iconId || externalLink
    const style = {
        display: 'grid',
        alignContent: 'center',
        gridTemplateColumns: hasIcon && `auto ${externalLink || simpleLink ? '20px' : '30px'}`,
        placeItems: hasIcon && 'center',
        columnGap: hasIcon ? (simpleLink ? '5px' : '10px') : undefined,
        marginLeft: center && 'auto',
        marginRight: center && 'auto',
        color: color && `var(--${color})`,
    }

    const IconElement = ({ id }) => {
        return <Icon onClick={onClick && onClick} id={id} color={simpleLink ? 'black' : 'white'} className={simpleLink && 'tiny'} />
    }

    if (href) {
        return (
            <a id={id && id} className={finalClassName} style={style} href={href} target={externalLink ? '_blank' : '_self'} rel={'noreferrer'}>
                {btnText}
                <IconElement id="link-ext" />
            </a>
        )
    }
    return (
        <button id={id && id} type={type && type} className={finalClassName} onClick={onClick && onClick} style={style} disabled={disabled}>
            {btnText}
            {iconId && <IconElement id={iconId} />}
        </button>
    )
}
