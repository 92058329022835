import campaign from './campaigns/cbu'
import support from './static-content/support'
import ira25C from './static-content/ira-25c'
import kj from './static-content/kj'
import altText from './static-content/altText'

const buPhone = '866-516-0198'

const hero = {
    ...campaign(),
    savingsTerminology: 'rebates',
}

const sections = [
    // subscribe({ campaignId: hero.subscriberCampaignId, color: 'gray'}),
    ira25C({ hasNavAnchor: true }),
    {
        id: 'how-heat-pumps-work',
        class: 'two-column-img-right',
        blurb: 'How Our Heat Pumps Heat AND Cool',
        detailsText:
            'Our all-climate heat pumps heat and cool any room, anywhere, in any season. Maybe you have a room that is unbearably cold during the winter, or maybe a space that requires you to drop the temperature of your entire house just to get that area comfortable during the summer. Your home should feel comfortable year-round.',
        imgSrc: '/images/how-heat-pumps-work-video-poster-summer.webp',
        imgAlt: `${altText} video poster for 4 Reasons To Choose Mitsubishi Electric Heat Pumps`,
        ctaText: 'Watch Video',
        ctaUrl: 'form-section',
        videoSrc: 'oETVJ7Nx9g0',
        backgroundCol: 'transparent',
    },
    {
        id: 'Applications',
        class: 'two-column-img-right',
        hasNavAnchor: true,
        // title: 'Going Ductless',
        blurb: 'A Solution For\nAny Space',
        // detailsText: 'Experience ultimate comfort and control in any room.',
        iconList: {
            title: 'Experience ultimate control in any room, whether ducted or ductless, by easily customizing your comfort settings based on your needs and lifestyle. Heat pumps are perfect for',
            items: [
                {
                    iconId: 'check',
                    text: 'Over-garage rooms',
                },
                {
                    iconId: 'check',
                    text: 'Workshops and Outbuildings',
                },
                {
                    iconId: 'check',
                    text: 'Attics',
                },
                {
                    iconId: 'check',
                    text: 'Additions',
                },
                {
                    iconId: 'check',
                    text: 'Garages or conversions',
                },
                {
                    iconId: 'check',
                    text: 'Bedrooms with hot/cold spots',
                },
                {
                    iconId: 'check',
                    text: 'Home offices',
                },
                {
                    iconId: 'check',
                    text: 'Whole-home heating and cooling',
                },
                {
                    iconId: 'check',
                    text: 'Basements',
                },
            ],
        },
        imgSrc: '/images/res-applications.webp',
        imgAlt: `${altText} 4 examples of interior applications for ductless heat pump systems, top left is a man in a home office, top right is a woman exercising in her home gym, bottom left is a garage/workshop, and bottom left is the interior of a tiny home.`,
    },
    support({ phone: buPhone }),
]

const header = {
    pageTitle: 'Upgrade to Mitsubishi Electric in the Northern Midwest: Rebates, Cold Climate Performance & More',
    pageDesc:
        "Discover the advantages of Mitsubishi Electric heat pump systems in the Northern Midwest. From generous rebates and incentives to superior performance in cold weather, find out why we're the smart choice.",
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: 'Get Started',
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
