import altText from './altText'

export const allProducts = [
    {
        id: 'wall-mounted',
        imgSrc: '/images/product/wall-mounted.webp',
        imgAlt: `${altText}wall-mounted indoor mini-split heat pump unit`,
        title: 'Wall-mounted',
        text: 'These mini-split units are mounted high up on the wall and out of your way. Wall-mounted units are great for living rooms and bedrooms, where personalized comfort is paramount.',
    },
    {
        id: 'ceiling-cassette',
        imgSrc: '/images/product/ceiling-cassette.webp',
        imgAlt: `${altText}recessed ceiling cassette indoor mini-split heat pump unit`,
        title: 'Ceiling-recessed',
        text: 'This modern, low-profile, recessed mini-split ceiling-cassette design takes up zero wall space, blends with your ceiling, and is perfect in the kitchen, where wall space is usually scarce.',
    },
    {
        id: 'intelli-heat',
        imgSrc: '/images/product/intelli-HEAT_200px.webp',
        imgAlt: `${altText}horizontal-ducted indoor mini-split heat pump unit`,
        title: 'Hybrid HVAC',
        text: 'Installed on your existing gas furnace, this smart system determines the best source of heat (gas or electricity) on cold days and even improves air conditioning efficiency on hot days.',
    },
    {
        id: 'air-handler',
        imgSrc: '/images/product/air-handler.webp',
        imgAlt: `${altText}air handler indoor mini-split heat pump unit`,
        title: 'Air Handler',
        text: 'All-electric heating and air-conditioning that uses your existing ductwork, replacing your heating-only furnace with a highly-efficient heat pump system that does not burn fossil fuels.',
    },
    {
        id: 'floor-mounted',
        imgSrc: '/images/product/floor-mounted.webp',
        imgAlt: `${altText}floor-mounted indoor mini-split heat pump unit`,
        title: 'Floor-mounted',
        text: 'Installed low on the wall, near the floor, these floor-mounted mini-split units make a great alternative to radiators and can fit into spaces with limited upper-wall space, like sunrooms or attics.',
    },
]
