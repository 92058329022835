export default function groupIncentivesByProviders(incentives) {
    const providers = []

    incentives.forEach((incentive) => {
        incentive.rebatePrograms.forEach((program) => {
            const existingProvider = providers.find((provider) => provider.name === program.adminName)

            // if provider is new, create new record and push it to "providers"
            if (!existingProvider) {
                providers.push({
                    name: program.adminName,
                    incentives: [program],
                })
                return
            }

            // for existing provider, push rebate only if id is unique
            const isExistingProgram = existingProvider?.incentives?.some((existingProgram) => {
                const programFound = existingProgram.id === program.id
                // console.log(`"${existingProgram.id}" = "${program.id}"? ${programFound}`)
                return programFound
            })

            if (isExistingProgram) {
                return
            }

            existingProvider.incentives.push(program)
        })
    })

    return providers
}
