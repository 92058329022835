import campaign from './campaigns/sbu'
import altText from './static-content/altText'
import subscribe from './static-content/subscribe'
import benefits from './static-content/benefits'
import indoorSolutions from './static-content/indoorSolutions'
import svz from './static-content/svz-ducted'
import control from './static-content/control'
import financing from './static-content/contentBoxFinancing'
import warranty from './static-content/contentBoxWarranty'
import download from './static-content/download'
import ira25C from './static-content/ira-25c'
import intelliHeat from './static-content/intelli-heat'

const buPhone = '844-497-6389'

const hero = campaign()

const sections = [
    ira25C({ hasNavAnchor: true }),
    {
        id: 'ductless',
        class: 'two-column-img-left',
        title: 'No Ducts?\nNo Problem',
        detailsText:
            "All-electric, all-climate heat pumps are designed for energy-efficiency and flexibility to solve your heating and cooling needs. Our systems are designed to fit any application, delivering personalized temperature control for one room or a whole home, with or without ducts. Read on to discover how to elevate your home's comfort.",
        imgSrc: '/images/nebu-spring-hero-img.webp',
        imgAlt: `${altText} happy family, man and woman in living room smiling and playing with child. to the right is an outdoor heat pump unit`,
        ctaText: 'Learn More',
        ctaUrl: 'form-section',
        // backgroundCol: 'yellow',
    },
    download({}),
    {
        id: 'Applications',
        class: 'two-column-img-right',
        // title: 'Going Ductless',
        blurb: 'A Solution For\nAny Space',
        // detailsText: 'Experience ultimate comfort and control in any room.',
        iconList: {
            title: 'Experience ultimate control in any room, whether ducted or ductless, by easily customizing your comfort settings based on your needs and lifestyle. Heat pumps are perfect for',
            items: [
                {
                    iconId: 'check',
                    text: 'Over-garage rooms',
                },
                {
                    iconId: 'check',
                    text: 'Workshops and Outbuildings',
                },
                {
                    iconId: 'check',
                    text: 'Attics',
                },
                {
                    iconId: 'check',
                    text: 'Additions',
                },
                {
                    iconId: 'check',
                    text: 'Garages or conversions',
                },
                {
                    iconId: 'check',
                    text: 'Bedrooms with hot/cold spots',
                },
                {
                    iconId: 'check',
                    text: 'Home offices',
                },
                {
                    iconId: 'check',
                    text: 'Whole-home heating and cooling',
                },
                {
                    iconId: 'check',
                    text: 'Basements',
                },
            ],
        },
        imgSrc: '/images/res-applications.webp',
        imgAlt: `${altText} 4 examples of interior applications for ductless heat pump systems, top left is a man in a home office, top right is a woman exercising in her home gym, bottom left is a garage/workshop, and bottom left is the interior of a tiny home.`,
    },
    intelliHeat({ title: 'Welcome to Hybrid Heating + Air Conditioning' }),
    indoorSolutions({ hasNavAnchor: true }),
    svz({}),
    benefits({}),
    control({ color: 'transparent' }),
    {
        id: 'Support',
        class: 'three-column-cards-w-image',
        title: 'The Support You Need',
        contentBoxes: [
            financing,
            warranty,
            {
                iconSrc: 'handshake',
                iconAlt: `${altText} call us for support`,
                blurb: 'Contact Us',
                detailsText: 'Have questions? Reach out to our Customer Care team at',
                phone: buPhone,
            },
        ],
        backgroundCol: 'blue',
    },
    subscribe({ campaignId: hero.subscriberCampaignId, color: 'gray' }),
]

const header = {
    pageTitle: 'Unlock Year-Round Comfort in the Southeast with Mitsubishi Electric: Rebates & Energy Efficiency',
    pageDesc:
        'Transform how you cool and heat your home. Mitsubishi Electric offers cost-saving solutions for Georgia, Florida, Mississippi, Alabama, and the Carolinas. Explore rebates and start saving!',
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: 'Get Started',
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
