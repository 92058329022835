import groupIncentivesByProviders from './groupIncentivesByProvider'

export default function parseIncentives(incentives, modelsToSearch) {
    let foundIncentives = []

    if (modelsToSearch) {
        const modelSet = new Set(modelsToSearch.map((model) => model.toLowerCase()))

        foundIncentives = incentives.filter((incentive) => {
            const rebateSku = incentive.product.displaySku.toLowerCase()
            return Array.from(modelSet).some((model) => rebateSku.includes(model))
        })
    } else {
        foundIncentives = incentives
    }

    console.log('incentives to parse:', foundIncentives)
    const incentivesByProvider = groupIncentivesByProviders(foundIncentives)

    const startTime = new Date()
    const currentYear = startTime.getFullYear()
    const nextYear = currentYear + 1

    const parsedIncentives = incentivesByProvider.map((provider) => {
        const incentive = provider.incentives[0]
        let details = ''

        incentive.type = 'Utility Rebate'

        const { offerTypes = [], importantDetails = [] } = incentive

        if (offerTypes.includes('tax_credit')) {
            incentive.isTaxCredit = true
            incentive.type = 'Tax Credit'
            importantDetails.push(`Must be filed with taxes in ${nextYear} if purchased during the ${currentYear} tax year.`)
        }

        if (offerTypes.includes('State Rebate')) {
            incentive.type = 'Tax Credit'
        }

        importantDetails.push(
            `This offer is not administered by Mitsubishi Electric and is subject to terms and conditions of ${incentive.adminName || incentive.name}.`,
        )

        details += importantDetails
            .map((detail) => {
                detail = detail.trim()
                return detail.endsWith('.') ? detail : `${detail}.`
            })
            .join(' ')

        const termsToFind = [
            'low income',
            'low-income',
            'moderate income',
            'moderate-income',
            ' hear ',
            ' h.e.a.r. ',
            ' her ',
            ' h.e.r. ',
            ' lmi ',
            ' l.m.i. ',
            ' ami ',
            ' a.m.i. ',
        ]

        if (termsToFind.some((term) => details.toLowerCase().includes(term))) {
            incentive.isLmiRebate = true
        }

        incentive.details = details.trimEnd()

        return incentive
    })

    return parsedIncentives
}
