import { numWithCommas } from './helpers'

export default function parseIRAIncentives(rawData) {
    const parsedIncentives = {
        isLowIncome: rawData.is_under_80_ami,
        isModerateIncome: rawData.is_under_150_ami,
        rebates: [],
        taxCredits: [],
        totalAmount: 0,
        totalAmountFormatted: '0',
    }

    const getHeatPumpRebates = (data) => {
        data.forEach((rebate) => {
            if (rebate.eligible && rebate.item.includes('Heat Pump Air')) {
                console.log(rebate)
                parsedIncentives.rebates.push(rebate)
                parsedIncentives.totalAmount = parsedIncentives.totalAmount + rebate.amount
            }
        })
    }

    const getHeatPumpTaxCredits = (data) => {
        data.forEach((taxCredit) => {
            if (taxCredit.eligible && taxCredit.item.includes('Heat Pump Air')) {
                console.log(taxCredit)
                parsedIncentives.taxCredits.push(taxCredit)
                parsedIncentives.totalAmount = parsedIncentives.totalAmount + taxCredit.amount
            }
        })
    }

    getHeatPumpRebates(rawData.pos_rebate_incentives)
    getHeatPumpTaxCredits(rawData.tax_credit_incentives)

    parsedIncentives.totalAmountFormatted = numWithCommas(parsedIncentives.totalAmount)

    return parsedIncentives
}
