import campaign from './campaigns/swbu'
import altText from './static-content/altText'
import download from './static-content/download'
import financing from './static-content/contentBoxFinancing'
import warranty from './static-content/contentBoxWarranty'
import svz from './static-content/svz-ducted'
import benefits from './static-content/benefits'
import ira25C from './static-content/ira-25c'
import mitsubishiSystems from './static-content/mitsubishiSystems'
import indoorSolutions from './static-content/indoorSolutions'
// import subscribe from './static-content/subscribe'

const buPhone = '855-947-1544'

const hero = {
    ...campaign(),
    ctaUrl: 'form-section',
    formInstructions:
        'Fill out this form and a qualified HVAC contractor in your area will contact you to answer your questions and schedule an on-site consultation.',
}

const sections = [
    // subscribe({ campaignId: hero.subscriberCampaignId }),
    ira25C({ hasNavAnchor: true }),
    {
        id: 'ductless',
        class: 'two-column-img-left',
        hasNavAnchor: true,
        title: 'No Ducts?\nNo Problem',
        detailsText:
            "All-electric, all-climate heat pumps are designed for energy-efficiency and flexibility to solve your heating and cooling needs. Our systems are designed to fit any application, delivering personalized temperature control for one room or a whole home, with or without ducts. Read on to discover how to elevate your home's comfort.",
        imgSrc: '/images/nebu-spring-hero-img.webp',
        imgAlt: `${altText} happy family, man and woman in living room smiling and playing with child. to the right is an outdoor heat pump unit`,
        ctaText: 'Learn More',
        ctaUrl: 'form-section',
        // backgroundCol: 'yellow',
    },
    {
        id: 'Applications',
        class: 'two-column-img-right',
        hasNavAnchor: true,
        // title: 'Going Ductless',
        blurb: 'A Solution For\nAny Space',
        // detailsText: 'Experience ultimate comfort and control in any room.',
        iconList: {
            title: 'Experience ultimate control in any room, whether ducted or ductless, by easily customizing your comfort settings based on your needs and lifestyle. Heat pumps are perfect for',
            items: [
                {
                    iconId: 'check',
                    text: 'Over-garage rooms',
                },
                {
                    iconId: 'check',
                    text: 'Workshops and Outbuildings',
                },
                {
                    iconId: 'check',
                    text: 'Attics',
                },
                {
                    iconId: 'check',
                    text: 'Additions',
                },
                {
                    iconId: 'check',
                    text: 'Garages or conversions',
                },
                {
                    iconId: 'check',
                    text: 'Bedrooms with hot/cold spots',
                },
                {
                    iconId: 'check',
                    text: 'Home offices',
                },
                {
                    iconId: 'check',
                    text: 'Whole-home heating and cooling',
                },
                {
                    iconId: 'check',
                    text: 'Basements',
                },
            ],
        },
        imgSrc: '/images/res-applications.webp',
        imgAlt: `${altText} 4 examples of interior applications for ductless heat pump systems, top left is a man in a home office, top right is a woman exercising in her home gym, bottom left is a garage/workshop, and bottom left is the interior of a tiny home.`,
    },
    {
        id: 'connect',
        class: 'two-column-img-left',
        // title: '4 Reasons',
        blurb: 'Connect with Specially Trained Technicians',
        detailsText:
            "We connect you directly to a Diamond Contractor or Ductless Pro, who are third-party manufacturer-trained HVAC contractors ready to install our all-climate heat pumps according to our engineer's specifications. Diamond Contractors and Ductless Pros receive ongoing product,maintenance, and installation training directly from Mitsubishi Electric. This is the reason they are the only contractors that can offer our limited 12-year factory warranty.",
        imgSrc: '/images/contractor-gauges.webp',
        imgAlt: `${altText} video poster for 4 Reasons To Choose Mitsubishi Electric Heat Pumps`,
        ctaText: 'Get Connected',
        ctaUrl: 'form-section',
        backgroundCol: 'yellow',
    },
    {
        id: 'how-heat-pumps-work',
        class: 'two-column-img-right',
        // title: '4 Reasons',
        blurb: 'How Our Heat Pumps Heat AND Cool',
        detailsText:
            'Our all-climate heat pumps heat and cool any room, anywhere, in any season. Maybe you have a room that is unbearably cold during the winter, or maybe a space that requires you to drop the temperature of your entire house just to get that area comfortable during the summer. Your home should feel comfortable year-round.',
        imgSrc: '/images/how-heat-pumps-work-video-poster-summer.webp',
        imgAlt: `${altText} video poster for 4 Reasons To Choose Mitsubishi Electric Heat Pumps`,
        ctaText: 'Watch Video',
        ctaUrl: 'form-section',
        videoSrc: 'oETVJ7Nx9g0',
        backgroundCol: 'transparent',
    },
    svz({}),
    mitsubishiSystems({}),
    // {
    // 	id: 'one-column-image',
    // 	class: 'one-column-image',
    // 	title: 'Not A Window Unit',
    // 	detailsText: 'Mitsubishi Electric heat pump HVAC systems consist of an outdoor heat pump connected to an indoor unit mounted on your ceiling or wall. That means our units do not block windows and become a more stable - and attractive - fixture in your home, while also ensuring a peaceful and restful night\'s sleep with their whisper-quiet operation.',
    // 	imgSrc: '/images/not-a-window-unit.svg',
    // 	backgroundCol: 'gray'
    // },
    download({}),
    // {
    // 	id: 'What’s New',
    // 	class: 'two-column-img-left',
    // 	blurb: 'What’s New with Heat Pumps',
    // 	detailsText: 'Learn how Mitsubishi Electric Heating and Air Conditioning has transformed heat pump technology, delivering the future of comfort.',
    // 	imgSrc: '/images/whats-new-w-heat-pumps-poster3.webp',
    // 	imgAlt: `${altText} video poster for What’s New with Heat Pumps`,
    // 	ctaText: 'Watch Video',
    // 	videoSrc: 'r5FvjZfOMSQ',
    // },
    benefits({}),
    indoorSolutions({}),
    {
        id: 'Support',
        class: 'three-column-cards-w-image',
        title: 'The Support You Need',
        contentBoxes: [
            financing,
            warranty,
            {
                iconSrc: 'handshake',
                iconAlt: `${altText} call us for support`,
                blurb: 'Contact Us',
                detailsText: 'Have questions? Reach out to our Customer Care team at',
                phone: buPhone,
            },
        ],
        backgroundCol: 'blue',
    },
]

const header = {
    pageTitle: 'Unlock Southern Comfort with Mitsubishi Electric: Savings & Energy Efficiency',
    pageDesc:
        'Transform how you cool and heat your home in the Southern states. Mitsubishi Electric offers cost-saving solutions for New Mexico, Texas, Oklahoma, Kansas, Missouri, Arkansas, and Louisiana.',
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: hero.ctaText,
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
