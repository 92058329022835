import { useState, useEffect } from 'react'
import ScrollLink from './modular/ScrollLink'
import Icon from './modular/Icon'
import Button from './modular/Button'

export default function Details(props) {
    const [blurbStyle, setBlurbStyle] = useState({ maxWidth: '1000px', margin: 'auto' })
    const [detailsTextStyle, setDetailsTextStyle] = useState(null)

    useEffect(() => {
        if (props.id === 'control') {
            setBlurbStyle({ textAlign: 'center', textShadow: '0 0 40px var(--site-bg)' })
        }

        if (props.class.includes('dark')) {
            setBlurbStyle({ color: 'var(--white)' })
        }

        if (!props.class.includes('two-column')) {
            setDetailsTextStyle({
                width: 'clamp(200px, 50vw, 800px)',
                marginLeft: 'auto',
                marginRight: 'auto',
            })
        }
    }, [props.id, props.class])

    return (
        <div className="details" style={{ display: props.videoIsHidden ? 'initial' : 'none' }}>
            {props.title && <h2 style={props.class.includes('one-column-info') ? { paddingBottom: 'var(--xl)' } : null}>{props.title}</h2>}

            {props.blurb && (
                <h3 style={blurbStyle} className={props.class.includes('download') ? 'h4' : null}>
                    {props.blurb}
                </h3>
            )}

            {props.detailsText && (
                <h6 style={detailsTextStyle ? detailsTextStyle : { whiteSpace: 'pre-wrap' }}>
                    {props.detailsText}
                    {props.download && (
                        <a target="_blank" rel="noreferrer" href="https://metus-cms-dev.s3.us-east-2.amazonaws.com/me_qpl_2024_04_15_12_18_18_0_6e02f01f39.pdf">
                            {' '}
                            {props.download}.
                        </a>
                    )}
                </h6>
            )}

            {props.detailsImgSrc && <img src={props.detailsImgSrc} alt={props.detailsImgAlt} />}

            {props.subBlurb && (
                <div>
                    <h4 style={{ paddingTop: 'var(--lg)', color: 'var(--accent)' }}>{props.subBlurb}</h4>

                    {props.listItems.map((item, index) => (
                        <h6 key={index}>{item.text}</h6>
                    ))}
                </div>
            )}

            {props.iconList && (
                <div className="icon-list-wrapper">
                    {props.iconList.title && <h5>{props.iconList.title}:</h5>}
                    {props.iconList.items.map((item, index) => (
                        <div key={index} className="icon-list-item" style={{ paddingLeft: 'var(--lg)' }}>
                            <Icon id={item.iconId ? item.iconId : 'check'} style={{ gridArea: 'unset', margin: '0' }} />
                            <p style={{ margin: '0' }}>{item.text}</p>
                        </div>
                    ))}
                </div>
            )}

            {props.ctaText ? (
                props.videoSrc ? (
                    <ScrollLink className="section-btn-wrapper" to={`#${props.id}`}>
                        <Button onClick={props.openVideo} iconId="play" text={props.ctaText} />
                    </ScrollLink>
                ) : props.ctaUrl.includes('https') ? (
                    <div className="section-btn-wrapper">
                        <Button text={props.ctaText} href={props.ctaUrl} />
                    </div>
                ) : (
                    <ScrollLink className="section-btn-wrapper" to={`#${props.ctaUrl}`}>
                        <Button text={props.ctaText} />
                    </ScrollLink>
                )
            ) : null}

            {props.footnote && props.id !== 'estimate' && <p className="footnote">{props.footnote}</p>}
        </div>
    )
}
