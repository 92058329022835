import altText from './static-content/altText'
import download from './static-content/download'
import financing from './static-content/contentBoxFinancing'
import warranty from './static-content/contentBoxWarranty'
import svz from './static-content/svz-ducted'
import benefits from './static-content/benefits'
import ira25C from './static-content/ira-25c'
import indoorSolutions from './static-content/indoorSolutions'
import mitsubishiSystems from './static-content/mitsubishiSystems'

const buPhone = '855-947-1544'

const hero = {
    bu: 'swbu',
    class: 'hero',
    staticCampaign: true,
    form: 'rebate',
    title: 'No Ducts?\nNo Problem',
    blurb: null,
    detailsText:
        "Our systems are designed to fit any application, delivering personalized temperature control for one room or a whole home, with or without ducts. Read on to discover how to elevate your home's comfort.",
    ctaText: null,
    ctaUrl: 'form-section',
    footnote: null,
    formInstructions:
        'Fill out this form and a qualified HVAC contractor in the Kansas City area will contact you to answer your questions and schedule an on-site consultation.',
    whatsNext: null,
    backgroundSrc: '/images/lifestyle/interior/couple-on-sofa.webp',
    leadCampaignId: '701Pd0000058Ax0IAE',
    // subscriberCta: 'Download Our Brochure',
    subscriberCampaignId: '7013q000001kmYXAAY',
    hideContactMethodSelector: false,
    hideContractorNumSelector: false,
    hideTimeframeSelector: false,
    hideLocationSelectors: false,
    hideSystemSelector: false,
    hideCommunicationsSelector: false,
    hideDownArrow: false,
}

const sections = [
    // subscribe({ campaignId: hero.subscriberCampaignId }),
    {
        id: 'Projects',
        class: 'map',
        title: `We Have Your Area Covered`,
        data: 'cd-jones-contractors',
        options: {
            centerCoords: { lat: 40.0140442405539, lng: -95.34645610092612 },
            zoomLevel: 7,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            clustering: true,
        },
    },
    ira25C('orange'),
    {
        id: 'Applications',
        class: 'two-column-img-right',
        // title: 'Going Ductless',
        blurb: 'A Solution For Any Problem',
        // detailsText: 'Experience ultimate comfort and control in any room.',
        iconList: {
            title: 'Experience ultimate control in any room, whether ducted or ductless, and make sure your family and friends are cozy and comfortable this winter season. Heat pumps are perfect for',
            items: [
                {
                    iconId: 'check',
                    text: 'Over-garage rooms',
                },
                {
                    iconId: 'check',
                    text: 'Workshops and Outbuildings',
                },
                {
                    iconId: 'check',
                    text: 'Attics',
                },
                {
                    iconId: 'check',
                    text: 'Additions',
                },
                {
                    iconId: 'check',
                    text: 'Garages or conversions',
                },
                {
                    iconId: 'check',
                    text: 'Bedrooms with hot/cold spots',
                },
                {
                    iconId: 'check',
                    text: 'Home offices',
                },
                {
                    iconId: 'check',
                    text: 'Whole-home heating and cooling',
                },
                {
                    iconId: 'check',
                    text: 'Basements',
                },
            ],
        },
        imgSrc: '/images/res-applications.webp',
        imgAlt: `${altText} 4 examples of interior applications for ductless heat pump systems`,
    },
    {
        id: 'connect',
        class: 'two-column-img-left',
        // title: '4 Reasons',
        blurb: 'Connect with Specially Trained Technicians',
        detailsText:
            "We connect you directly to a Diamond Contractor, a third-party manufacturer-trained HVAC professional ready to install our all-climate heat pumps according to our engineer's specifications. Diamond Contractors receive ongoing product, maintenance, and installation training directly from Mitsubishi Electric. This is the reason they are the only contractors that can offer our limited 12-year factory warranty.",
        imgSrc: '/images/contractor-gauges.webp',
        imgAlt: `${altText} video poster for 4 Reasons To Choose Mitsubishi Electric Heat Pumps`,
        ctaText: 'Get Connected',
        ctaUrl: 'form-section',
        backgroundCol: 'yellow',
    },
    {
        id: 'how-heat-pumps-work',
        class: 'two-column-img-right',
        // title: '4 Reasons',
        blurb: 'How Our Heat Pumps Heat AND Cool',
        detailsText:
            'Our all-climate heat pumps heat and cool any room, anywhere, in any season. Maybe you have a room that is unbearably cold during the winter, or maybe a space that requires you to drop the temperature of your entire house just to get that area comfortable during the summer. Your home should feel comfortable year-round.',
        imgSrc: '/images/how-heat-pumps-work-video-poster.webp',
        imgAlt: `${altText} video poster for 4 Reasons To Choose Mitsubishi Electric Heat Pumps`,
        ctaText: 'Watch Video',
        ctaUrl: 'form-section',
        videoSrc: 'oETVJ7Nx9g0',
        backgroundCol: 'transparent',
    },
    svz({}),
    mitsubishiSystems({}),
    download({}),
    benefits({}),
    indoorSolutions({}),
    {
        id: 'Support',
        class: 'three-column-cards-w-image',
        title: 'The Support You Need',
        contentBoxes: [
            financing,
            warranty,
            {
                iconSrc: 'handshake',
                iconAlt: `${altText} call us for support`,
                blurb: 'Contact Us',
                detailsText: 'Have questions? Reach out to our Customer Care team at',
                phone: buPhone,
            },
        ],
        backgroundCol: 'blue',
    },
]

const header = {
    pageTitle: 'Unlock Southern Comfort with Mitsubishi Electric: Savings & Energy Efficiency',
    pageDesc:
        'Transform how you cool and heat your home in the Southern states. Mitsubishi Electric offers cost-saving solutions for New Mexico, Texas, Oklahoma, Kansas, Missouri, Arkansas, and Louisiana.',
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: 'Get Started',
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
