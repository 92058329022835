import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '4/1/2024'
    const end = '9/16/2024'
    const amount = 0

    const data = {
        // defaults
        bu: 'sbu',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000001knTGAAY',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/exterior/cbu-hero-fall.webp',
        defaultTitle: defaults.title,
        defaultBlurb: '',
        defaultDetails:
            "Take control of your home's comfort by choosing a ductless heat pump system. Perfect for your home office, basement, garage or your whole home.",
        defaultFootnote: '',
        utilityProduct: defaults.utilityProduct,
        whatsNext: defaults.whatsNext,
        submitFormBtnText: null,
        // ME_Residential_SBU_Spring_2024_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        campaignId: '701Pd000006xpPdIAI',
        campaignHero: 'rebate',
        campaignCtaText: '',
        campaignImage: '/images/lifestyle/interior/couple-kitchen-ez-fit.webp',
        campaignTitle: 'Comfort, inside and out',
        campaignBlurb: 'Stay comfortable this spring with big savings.',
        campaignDetails:
            "Take control of your home's comfort by choosing a ductless heat pump system. Perfect for your home office, basement, garage or your whole home.",
        campaignFootnote: `Instant rebate applies to purchase of a qualifying system used in a single-family residential installation between ${start} and ${end} with participating contractors in AL, FL, TN, MS, LA, and GA. Submit your information to be contacted by a participating contractor.`,
        hideContractorNumSelector: false,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}
