import { useState, useRef, useEffect, useReducer } from 'react'
import { useSearchParams } from 'react-router-dom'
import InputText from './modular/InputText'
import validateField from '../lib/validateField'
import { findInstantRebates, findEcoRebates } from '../lib/findRebates'
import { formatPhone, formatZip } from '../lib/helpers'
import { states } from './page content/campaigns/_states'
import { submitFormData, submitToSandbox } from '../lib/queries'
import getGeoData from '../lib/getGeoData'
import ThankYouLead from './ThankYouLead'
import LoadingSpinner from './modular/LoadingSpinner'
import ModalButton from './modular/ModalButton'
import Button from './modular/Button'
import { numWithCommas } from '../lib/helpers'
import conversions from '../lib/conversions'
import InputRadioBtnGroup from './modular/InputRadioBtnGroup'
import Markdown from 'markdown-to-jsx'

export default function LeadFormRebate({ content, formClass }) {
    const savingsTerminology = content.savingsTerminology ? content.savingsTerminology : 'rebates and tax credits'

    const [formData, setFormData] = useState({
        FirstName: { value: '' },
        LastName: { value: '' },
        Phone: { value: '' },
        Email: { value: '' },
        ZipCode: { value: '' },
        Street: { value: '' },
        I_Am_A: { value: '' },
        ServiceNeeds: { value: '', valid: true },
        HVACneeds: { value: '', valid: true },
        Rooms: { value: '', valid: true },
        PlanToPurchase: { value: '', valid: true },
        NoOfContractor: { value: '', valid: true },
        BestWayToReachYou: { value: '', valid: true },
        CustomerComments: { value: '', valid: true },
        ReceiveEmailCommunication: { value: '', valid: true },
    })
    const [formIsValid, setFormIsValid] = useState(false)
    const [campaignId, setCampaignId] = useState(content.leadCampaignId)
    const [formTitle, setFormTitle] = useState(content.formTitle ? content.formTitle : 'Get Started Here')
    const [leadSource, setLeadSource] = useState('Landing Page')
    const [gotLocation, setGotLocation] = useState(false)
    const [userState, setUserState] = useState('')
    const [userCity, setUserCity] = useState('')
    const [thankYouHeadline, setThankYouHeadline] = useState('')
    const [leadFormSubmitted, setLeadFormSubmitted] = useState(false)

    const [rebateMessage, setRebateMessage] = useState(`Let's start by finding ${savingsTerminology} available based on your project's location.`)
    const [incentivesToDisplay, setIncentivesToDisplay] = useState([])
    const [rebateDetailsVisible, toggleRebateDetailsVisible] = useReducer((rebateDetailsVisible) => !rebateDetailsVisible, false)
    const [areInstructionsVisible, setAreInstructionsVisible] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const tokenField = useRef()

    const GTMTrigger = content.GTMTrigger ? content.GTMTrigger : 'hero_form'

    const currentPage = window.location.pathname.substring(1)
    const currentUrlObject = new URL(document.location)
    const currentPath = currentUrlObject.pathname
    const params = currentUrlObject.searchParams

    const rebateFootnoteSymbol = '#'

    useEffect(() => {
        // monitor and log campaign ID changes
        console.log('current campaign ID:', campaignId)
        console.log('current campaign details:', content)
    }, [content, campaignId])

    /* ---------------- URL PARAMETERS ---------------- */
    useEffect(() => {
        // if url params or a specific page, change lead source and campaign ID
        const newLeadSource = params.get('utm_source')
        const newCampaignId = params.get('campaign_id')
        newLeadSource && setLeadSource(newLeadSource)
        // only update campaign ID from URL if campaign is running
        if (content.isRunning && newCampaignId) {
            console.log('setting new campaign ID from URL:', newCampaignId)
            setCampaignId(newCampaignId)
        }
    }, [currentPath, params, content.isRunning])

    /* ---------------- FIELD VALUES HANDLER ---------------- */
    const handleChange = (e) => {
        const field = e.target
        let { name, type, value, required } = field
        let checked

        if (name === 'Phone' && value) {
            value = formatPhone(value)
        }

        if (name === 'ZipCode' && value) {
            value = formatZip(value)
        }

        if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
            checked = e.target.checked
        }

        const isValid = validateField(type, name, required, value)
        // console.log('changed:', name, value, 'valid:', isValid)

        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: {
                    value: type === 'checkbox' ? checked : value,
                    valid: isValid,
                },
            }
        })
    }

    useEffect(() => {
        const allFieldsValid = Object.keys(formData).every((key) => formData[key].valid)
        setFormIsValid(allFieldsValid)
    }, [formData])

    /* ---------------- LOCAL STORAGE HANDLER ---------------- */
    const handleStorageReset = () => {
        console.log(`--> lead form resetting `)
        // handle conversion and storage
        conversions('lead', null, true)
        setThankYouHeadline('Form reset, now reload page')
    }

    useEffect(() => {
        // handle local storage items and thank-you
        const userName = localStorage.getItem('userName')
        const isSubmitted = localStorage.getItem(`${currentPage}_leadFormSubmitted`)
        const Email = localStorage.getItem('Email')

        if (Email && (!formData.Email || formData.Email.value !== Email)) {
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    Email: {
                        value: Email,
                        valid: true,
                    },
                }
            })
        }

        isSubmitted === 'true' && setLeadFormSubmitted(true)

        if (userName && (!formData.FirstName || formData.FirstName.value !== userName)) {
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    FirstName: {
                        value: userName,
                        valid: true,
                    },
                }
            })
        }
    }, [formData.Email, formData.FirstName, currentPage])

    /* ---------------- REBATES FINDER FUNCTIONS ---------------- */
    const handleUiSearchStart = () => {
        setFormTitle('Searching...')
        setIsSearching(true)
        setRebateMessage(`For incentives in ${formData.ZipCode.value}`)
        setIncentivesToDisplay([])
    }

    const handleUiSearchEnd = (title, message, incentives) => {
        if (title) {
            setFormTitle(title)
        }
        if (message) {
            setRebateMessage(message)
        }
        if (incentives?.length > 0) {
            setIncentivesToDisplay(incentives)
        }
        setIsSearching(false)
    }

    const findAllRebates = async (location) => {
        console.log('location:', location)
        const allRebates = []
        const foundState = states.find((state) => state.name === location.state)
        if (!foundState) return console.log(`${location.state} not found`)
        console.log('foundState:', foundState)

        // search for instant and Eco (utility) rebates
        const instantRebate = findInstantRebates(location.zip, foundState, content.staticCampaign, campaignId)
        const utilityRebates = await findEcoRebates(location.zip, foundState.utilityProduct)

        if (instantRebate?.amount > 0) {
            allRebates.push(instantRebate)
        }

        utilityRebates?.forEach((utilRebate) => {
            if (utilRebate) {
                allRebates.push(utilRebate)
            }
        })
        console.log('---------> all rebates found:', allRebates)
        return allRebates
    }

    const handleUiUpdates = (location, rebates) => {
        // wait before showing "What's Next?" message
        setTimeout(() => setAreInstructionsVisible(true), 3000)

        if (rebates?.length === 0) {
            handleUiSearchEnd(
                'Savings May Be Available',
                `Though we couldn't find any utility rebates in ${location.city}, ${location.state}, HVAC contractors in your area may have special offers. Schedule an in-home consultation with a preferred local contractor to discuss savings and payment options.`,
            )
            return
        }

        const title = 'Incentives Found!'
        const message = `In ${location.city}, ${location.state}:`

        handleUiSearchEnd(title, message, rebates)
    }

    /* ---------------- GEO ADDRESS FINDER ---------------- */

    const geoLocationFail = (error) => {
        console.log(error.code, error.message)
        handleUiSearchEnd(null, `Please enter your home zip code below to search for ${savingsTerminology} available in your area.`)
    }

    const geoLocationSuccess = async (position) => {
        handleUiSearchStart()
        console.log('geoLocationSuccess() function started', position)
        const userLat = position.coords.latitude
        const userLng = position.coords.longitude
        const geoData = await getGeoData({ lat: userLat, lng: userLng })

        if (!geoData.success) {
            console.error('user geoData FAIL:', geoData)
            geoLocationFail({ code: 1, message: geoData.data })
            return
        }

        console.log('user geoData SUCCESS:', geoData)
        handleRebateSearch(geoData)
    }

    const handleRebateSearch = async (geoDataProp) => {
        console.log('geoDataProp', geoDataProp)
        const zipToSearch = geoDataProp?.data.zip || formData.ZipCode.value
        console.log('zipToSearch', zipToSearch)
        const testZips = ['00111', '00112', '00113', '00114', '00115', '00116', '00119']

        // handle zip codes used for testing form
        if (testZips.includes(zipToSearch)) {
            const finalPage = currentPage === '' ? 'home' : currentPage
            const currentPageCaps = finalPage.toUpperCase()
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    LastName: {
                        value: `${currentPageCaps} Form Test`,
                        valid: true,
                    },
                    Email: {
                        value: `${finalPage}@test.com`,
                        valid: true,
                    },
                    Phone: {
                        value: formatPhone('2345678901'),
                        valid: true,
                    },
                    Street: {
                        value: `${zipToSearch} ${finalPage} ln`,
                        valid: true,
                    },
                }
            })
            handleUiSearchEnd(
                'Welcome tester',
                'For your convenience, some required text fields have been pre-filled. Please add something meaningful under "First Name".',
            )
            return
        }

        // use Google geocode API to validate user-inputted zip code
        const geoData = geoDataProp || (await getGeoData({ zip: zipToSearch }))

        // if geodata lookup fails
        if (!geoData.success) {
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    ZipCode: {
                        value: '',
                        valid: false,
                    },
                }
            })
            handleUiSearchEnd('Please Try Another...', `Sorry, '${zipToSearch}' failed validation. Please check for typos or try another U.S. zip code.`)
            return
        }

        const userLocation = geoData.data

        if (userLocation.country !== 'United States') {
            setTimeout(
                () => handleUiSearchEnd(`Rebate information is not available for ${userLocation.country}, only for locations within the United States.`),
                2000,
            )
            setTimeout(() => setAreInstructionsVisible(true), 3000)
            return
        }

        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                ZipCode: {
                    value: zipToSearch,
                    valid: true,
                },
            }
        })

        const rebates = await findAllRebates(userLocation)

        setUserCity(userLocation.city)
        setUserState(userLocation.state)

        handleUiUpdates(userLocation, rebates)
    }

    /* ---------------- BROWSER GEO LOCATION ---------------- */
    const getGeoLocation = () => {
        if (!window.navigator.geolocation) {
            alert('Geolocation is not supported by this browser.')
            return
        }
        window.navigator.geolocation.getCurrentPosition(geoLocationSuccess, geoLocationFail, { timeout: 2000 })
    }

    const triggerLocation = () => {
        // console.log(`Updated ID: ${campaignId}`)
        !gotLocation && getGeoLocation()
        setGotLocation(true)
    }

    const handleSearchClick = () => {
        handleUiSearchStart()
        handleRebateSearch()
    }

    /* ---------------- 'ENTER' KEY TRIGGERS ZIP SEARCH ---------------- */
    const [zipFocused, setZipFocused] = useState(false)

    const toggleZipFocus = () => setZipFocused(!zipFocused)
    const handleZipFocus = () => {
        toggleZipFocus()
        triggerLocation()
    }
    const handleEnterKey = (e) => e.key === 'Enter' && handleSearchClick()

    /* ---------------- SUBMIT FORM DATA TO CRM ---------------- */
    const buForm = useRef() // parent <form>
    const handleFormSubmit = (e) => {
        e.preventDefault()

        formIsValid &&
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LfByKkaAAAAAOyd9o6yhtx5vH7ocTGLzihvuJZQ', { action: 'lead_form_submit' }).then((token) => {
                    tokenField.current.value = token
                    setIsSubmitting(true)

                    // prepare form data
                    const data = new FormData(e.target)
                    data.append('CampaignID', campaignId)
                    data.append('LeadSource', leadSource)
                    data.append('Journey', true)
                    data.append('LeadType', 'Lead')
                    // auto-assign contractors:
                    // data.append('00N3100000GpFau', 'true')
                    data.append('RecordType', '012i0000000xpJ6')
                    data.append('Company', '[not provided]')

                    // submit form data
                    const response = async () => {
                        if (content.sandbox) {
                            return submitToSandbox(data)
                        }
                        submitFormData(data)
                        for (let pair of data.entries()) {
                            console.log(pair[0] + ', ' + pair[1])
                        }
                        return
                    }

                    if (response()) {
                        // handle google Enhanced Conversions
                        conversions(
                            'lead',
                            {
                                name: formData.FirstName.value,
                                email: formData.Email.value,
                                city: userCity,
                                state: userState,
                                zip: formData.ZipCode.value,
                            },
                            null,
                        )
                        // append URL with "thank-you" param
                        const params = Object.fromEntries(searchParams)

                        // Add the new parameter.
                        params['thank-you'] = true

                        // Set the search parameters to the new object.
                        setSearchParams(params)

                        // replace form with thank you message
                        setThankYouHeadline(`Thank you, ${formData.FirstName.value}!`)
                        setLeadFormSubmitted(true)
                        setIsSubmitting(false)
                    }
                })
            })
    }

    if (isSubmitting)
        return (
            <div className={formClass}>
                <LoadingSpinner loadingMessage={'Sending your information...'} />
            </div>
        )

    if (leadFormSubmitted)
        return (
            <ThankYouLead
                onClick={handleStorageReset}
                headline={thankYouHeadline}
                className="glass-panel"
                thankYou="You can expect to hear from a licensed HVAC contractor within 2 business days."
                Email={formData.Email.value}
            />
        )

    return (
        <form
            onSubmit={(e) => handleFormSubmit(e)}
            action={GTMTrigger}
            id="form"
            className={formClass}
            autoComplete="on"
            // onPointerEnter={triggerLocation}
            ref={buForm}
        >
            <input type="hidden" id="token" name="token" value="token" ref={tokenField} />
            <input type="hidden" id="city-input" name="city" value={userCity} />
            <input type="hidden" id="state-input" name="state" value={userState} />
            <input type="hidden" id="country-input" name="Country" value="United States" />
            <div id="rebate-wrapper">
                <h2 className="h4 center" style={{ color: 'var(--accent)' }}>
                    {formTitle}
                </h2>
                <p className="rebate-message">{rebateMessage}</p>
                {isSearching ? (
                    <LoadingSpinner hideMessage infinite />
                ) : (
                    <div className="incentives-wrapper" style={{ display: incentivesToDisplay?.length === 0 ? 'block' : undefined }}>
                        {incentivesToDisplay?.length > 0 &&
                            incentivesToDisplay?.map((incentive, i) => {
                                const finalAmount = incentive.amount || incentive.rebateLimit || incentive.purchaseRebate?.amount
                                const amountLabel = finalAmount > 0 ? `$${numWithCommas(finalAmount)}` : 'See Info'
                                return (
                                    <div key={i + incentive.adminName} className="incentive-row">
                                        <p className="incentive-name">{incentive.name || incentive.adminName}:</p>
                                        <p>
                                            <span className="incentive-amount">
                                                {amountLabel}
                                                {/* <sup>{i + 2}</sup> */}
                                                <ModalButton mini type={'button'} text="info">
                                                    <p className="incentive-modal-type-heading">{incentive.type} Offer</p>
                                                    <p className="h5 bold">
                                                        {incentive.amountLabel || amountLabel}
                                                        <sup>{rebateFootnoteSymbol}</sup>
                                                    </p>
                                                    <p className="bold">{incentive.name || incentive.adminName}</p>
                                                    {incentive.purchaseDates && (
                                                        <>
                                                            <p>
                                                                Valid <span className="bold">{incentive.purchaseDates.buyAfter || 'today'}</span> to{' '}
                                                                <span className="bold">{incentive.purchaseDates.buyBefore || '(no end date specified)'}</span>
                                                            </p>
                                                        </>
                                                    )}
                                                    <Markdown>{`Details: ${incentive.details}`}</Markdown>
                                                    <p className="incentive-modal-disclaimer">
                                                        <sup>{rebateFootnoteSymbol}</sup>The information displayed here does not constitute a legally binding
                                                        agreement.{' '}
                                                        {incentive.adminName === 'Mitsubishi Electric'
                                                            ? ''
                                                            : 'This offer is not administered by Mitsubishi Electric and is subject to terms and conditions specified by the provider. This incentive information is provided by a 3rd-party service and is subject to change at the discretion of the provider. Mitsubishi Electric is not responsible for any outdated or inaccurate information. '}
                                                        Any amounts shown are estimates only based on purchase and installation of a Mitsubishi Electric
                                                        MXZ-3C30 heat pump system. Final savings are dependent on actual equipment installed and other
                                                        eligibility qualifications.
                                                    </p>
                                                </ModalButton>
                                            </span>
                                        </p>
                                    </div>
                                )
                            })}
                    </div>
                )}

                <div id="location-info-wrapper" style={{ display: 'grid' }}>
                    <InputText
                        id="zip"
                        name="ZipCode"
                        label="Zip Code"
                        type="text"
                        maxLength="5"
                        placeholder="U.S. Only"
                        onFocus={handleZipFocus}
                        onBlur={toggleZipFocus}
                        onKeyPress={handleEnterKey}
                        onChange={handleChange}
                        value={formData.ZipCode.value}
                        isValid={formData.ZipCode.valid}
                        errorMsg="Invalid Zip"
                        required
                    />
                    <div className="btn secondary" onClick={handleSearchClick}>
                        Search
                    </div>
                </div>

                <div
                    className="form-instructions"
                    style={areInstructionsVisible ? { maxHeight: '300px', visibility: 'visible' } : { maxHeight: '0px', visibility: 'hidden' }}
                >
                    <h4 className="h6 mar-btm-0">What's next?</h4>

                    <p style={{ margin: 'var(--spc-sm-h) 0' }}>
                        {content.whatsNext ? (
                            <span>
                                Not ready to consult with a contractor? Call{' '}
                                <a href={`tel:+1-${content.whatsNext}`} className="phone">
                                    {content.whatsNext}
                                </a>{' '}
                                to speak with a Mitsubishi Electric customer service representative.
                            </span>
                        ) : (
                            <span>Complete the form below to request a consultation with a licensed HVAC contractor in your area.</span>
                        )}
                    </p>
                </div>
            </div>
            <div id="lead-form-wrapper">
                <div className="footnote mar-0">*Asterisk Denotes a Required Field</div>
                <div className="contact-info-wrapper">
                    <InputText
                        id="first-name"
                        name="FirstName"
                        label="First Name"
                        type="text"
                        placeholder="given name"
                        value={formData.FirstName.value}
                        onChange={handleChange}
                        isValid={formData.FirstName.valid}
                        required
                    />

                    <InputText
                        id="last-name"
                        name="LastName"
                        label="Last Name"
                        type="text"
                        placeholder="surname"
                        value={formData.LastName.value}
                        onChange={handleChange}
                        isValid={formData.LastName.valid}
                        required
                    />

                    <InputText
                        id="lead-email"
                        name="Email"
                        label="Email"
                        type="email"
                        placeholder="name@domain.com"
                        maxLength="42"
                        value={formData.Email.value}
                        onChange={handleChange}
                        isValid={formData.Email.valid}
                        required
                    />

                    <InputText
                        id="phone"
                        name="Phone"
                        label="Phone No."
                        type="tel"
                        placeholder="10-digit, U.S. numbers only"
                        value={formData.Phone.value}
                        onChange={handleChange}
                        isValid={formData.Phone.valid}
                        required
                    />
                    <InputText
                        id="street"
                        name="Street"
                        label="Street Address"
                        type="text"
                        placeholder="House Number & Street"
                        value={formData.Street.value}
                        onChange={handleChange}
                        isValid={formData.Street.valid}
                        required
                    />
                    {userCity && (
                        <div className="flex-parent align-center">
                            <p className="no-margin bold">
                                {userCity}, {userState} {formData.ZipCode.value}
                            </p>
                        </div>
                    )}
                </div>
                <InputRadioBtnGroup
                    id="input-wrapper"
                    title="I am interested in"
                    name="I_Am_A"
                    options={['New Customer', 'Existing Customer']}
                    labels={['Purchasing a new system', 'Servicing an existing system']}
                    handleChange={handleChange}
                    selectedValue={formData.I_Am_A.value}
                    required
                />

                {/* NEW CUSTOMER ONLY: */}
                {formData.I_Am_A.value === 'New Customer' && (
                    <div id="new-system-wrapper">
                        <InputRadioBtnGroup
                            id="needs-wrapper"
                            title="What is the scope of your HVAC needs?"
                            name="Rooms"
                            options={['Single Room', 'Multiple Rooms', 'Entire System Upgrade', 'Other']}
                            handleChange={handleChange}
                            selectedValue={formData.Rooms.value}
                            required
                        />
                        {formData.Rooms.value === 'Other' && (
                            <InputText
                                id="needs-other"
                                name="HVACneeds"
                                label="Describe your HVAC needs"
                                textArea
                                placeholder="Describe your current HVAC system"
                                onChange={handleChange}
                                value={formData.HVACneeds.value}
                                isValid={formData.HVACneeds.valid}
                                required
                            />
                        )}
                        {!content.hideTimeframeSelector && (
                            <InputRadioBtnGroup
                                id="timeframe-wrapper"
                                title="When are you planning to purchase?"
                                name="PlanToPurchase"
                                options={['Immediately', '0-3 months', '3-6 months', '6-12 months', '12+ months']}
                                labels={['Immediately', '0-3 Months', '3-6 Months', '6-12 Months', '12+ Months']}
                                handleChange={handleChange}
                                selectedValue={formData.PlanToPurchase.value}
                                required
                            />
                        )}

                        {!content.hideContractorNumSelector && (
                            <InputRadioBtnGroup
                                id="contractors-wrapper"
                                title="How many contractors would you like to be matched with?"
                                name="NoOfContractor"
                                options={['1', '2', '3']}
                                handleChange={handleChange}
                                selectedValue={formData.NoOfContractor.value}
                                required
                            />
                        )}
                        {!content.hideContactMethodSelector && (
                            <InputRadioBtnGroup
                                id="contact-wrapper"
                                title="How would you prefer to be contacted?"
                                name="BestWayToReachYou"
                                options={['Email', 'Call', 'Text']}
                                handleChange={handleChange}
                                selectedValue={formData.BestWayToReachYou.value}
                                footnote="Data rates from your wireless provider may apply"
                                required
                            />
                        )}
                    </div>
                )}

                {/* EXISTING CUSTOMER ONLY: */}
                {formData.I_Am_A.value === 'Existing Customer' && (
                    <InputText
                        id="service"
                        name="ServiceNeeds"
                        label="Describe your system and service needed"
                        textArea
                        placeholder="Describe your current HVAC system"
                        onChange={handleChange}
                        value={formData.ServiceNeeds.value}
                        isValid={formData.ServiceNeeds.valid}
                        required
                    />
                )}

                {/* EITHER NEW OR EXISTING CUSTOMER: */}
                {formData.I_Am_A.value ? (
                    <div>
                        <InputText
                            id="CustomerComments"
                            name="CustomerComments"
                            label="Additional Comments?"
                            textArea
                            placeholder="Please add any additional information here"
                            onChange={handleChange}
                            value={formData.CustomerComments.value}
                            isValid={formData.CustomerComments.valid}
                        />

                        <div className="final-form-checkbox-wrapper">
                            <div id="communications-wrapper">
                                <input id="ReceiveEmailCommunication" name="ReceiveEmailCommunication" type="checkbox" value={true} onChange={handleChange} />
                                <label className="visible" htmlFor="ReceiveEmailCommunication">
                                    I would like to receive email and communications from Mitsubishi Electric Trane HVAC in the future
                                </label>
                            </div>
                            <Button
                                className="g-recaptcha"
                                type="submit"
                                id="bu-form-submit"
                                text={content.submitFormBtnText || 'Submit Form'}
                                disabled={!formIsValid || isSubmitting}
                            />
                        </div>
                    </div>
                ) : null}
                <span className="center">
                    *<span className="footnote">Required Field</span>
                </span>
            </div>
        </form>
    )
}
