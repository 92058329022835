import altText from './altText'

export default function mitsubishiSystems({ id = 'mitsubishi-systems', color = 'site' }) {
    return {
        id: id,
        class: 'three-column-cards-w-image',
        blurb: 'Mitsubishi Electric Systems Are...',
        contentBoxes: [
            {
                title: 'Severe Weather-Proof',
                detailsText:
                    'Our high-efficiency heat pumps are ready for when extreme weather strikes. By being able to run on a portable invertor-driven generator, our mini-split systems will keep your family comfortable even when the power is out.',
                imgSrc: '/images/generator_house_simple_2022_final.svg',
                imgAlt: `${altText} product row`,
            },
            {
                title: 'Not Window Units',
                detailsText:
                    "Mitsubishi Electric ductless heating and cooling systems consist of an outdoor heat pump connected to an indoor unit mounted on your ceiling or wall. That means our mini-split units do not block windows and become a more stable - and attractive - fixture in your home, while also ensuring a peaceful night's sleep with their whisper-quiet operation.",
                imgSrc: '/images/not-a-window-unit.svg',
                imgAlt: `${altText} interior bedroom mini-split wall unit heat pump vs window unit comparison`,
            },
            {
                title: 'The Future of Comfort',
                detailsText:
                    'We are constantly thinking of ways to make all of your moments special. Comfort should be seamless and innovative, which is why we have features like our 3D i-see Sensor® to automatically adjust the output of the unit based on occupancy and ensure your desired temperature.',
                imgSrc: '/images/3D-i-see.webp',
                imgUrl: '',
                imgAlt: `${altText} father daughter mini-split living room clean healthy air`,
            },
        ],
        backgroundCol: color,
    }
}
