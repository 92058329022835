import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '9/2/2024'
    const end = '12/14/2024'
    const amount = 500

    const data = {
        // defaults
        bu: 'cbu',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000001kkbAAAQ',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/interior/cbu-spring.webp',
        defaultTitle: defaults.title,
        defaultBlurb: defaults.blurb,
        defaultDetails: defaults.detailsText,
        defaultFootnote: '',
        utilityProduct: defaults.utilityProduct,
        whatsNext: defaults.whatsNext,
        submitFormBtnText: null,
        // ME_Residential_CBU_Fall_2024_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        campaignId: '701Pd00000FaY2YIAV',
        campaignHero: 'rebate',
        campaignCtaText: '',
        campaignImage: '/images/lifestyle/exterior/fall-neighborhood.webp',
        campaignTitle: defaults.title,
        campaignBlurb: defaults.blurb,
        campaignDetails: `Take control of your home's comfort by choosing a ductless heat pump system. Perfect for your home office, basement, garage or any unconditioned space in your home.`,
        campaignFootnote: `Limit one $${amount} instant rebate per multi-room system sold on single-family residential installations. Discount is flat $${amount} per qualifying system purchased from and installed by a participating contractor between ${start} and ${end}. Limited to systems purchased in IA, IL, IN, KY, MI, MN, ND, NE, OH, SD, WI and WV.`,
        hideContractorNumSelector: true,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}
