import campaign from './campaigns/mabu'
import altText from './static-content/altText'
import subscribe from './static-content/subscribe'
// import estimate from './static-content/estimate'
import financing from './static-content/contentBoxFinancing'
import warranty from './static-content/contentBoxWarranty'
import download from './static-content/download'
import ira25C from './static-content/ira-25c'
import intelliHeat from './static-content/intelli-heat'
import kumo from './static-content/control'
import indoorSolutions from './static-content/indoorSolutions'
import benefits from './static-content/benefits'
import svz from './static-content/svz-ducted'

const buPhone = '855-745-4749'

const hero = {
    ...campaign(),
    formInstructions:
        'Fill out this form and a qualified HVAC contractor in your area will contact you to answer your questions and schedule an on-site consultation.',
    thankYou: 'Ask your contractor to help you stack up the rebates from Mitsubishi and from your local utilities.',
}

const sections = [
    ira25C({ hasNavAnchor: true }),
    download({}),
    {
        id: 'all-electric',
        class: 'two-column-img-left',
        title: 'How Our Heat Pumps Heat AND Cool',
        detailsText:
            'Our all-climate heat pumps heat and cool any room, anywhere, in any season. Maybe you have a room that is unbearably cold during the winter, or maybe a space that requires you to drop the temperature of your entire house just to get that area comfortable during the summer. Your home should feel comfortable year-round.',
        imgSrc: '/images/how-heat-pumps-work-video-poster-summer.webp',
        imgAlt: `${altText} preview/poster for "how heat pumps work" video`,
        ctaText: 'How heat pumps work',
        videoSrc: 'ljkDJbnvwqs',
        backgroundCol: 'gray',
    },
    intelliHeat({}),
    svz({ ctaUrl: 'form-section' }),
    indoorSolutions({ ctsText: 'Get Started' }),
    // {
    // 	id: 'Benefits',
    // 	class: 'one-column-info',
    // 	title: `Technology With Benefits`,
    // 	blurb: `As the leader in heat pump technology, Mitsubishi Electric advances technologies that reduce waste output while increasing energy efficiency and eliminating dependence on fossil fuels.`,
    // 	backgroundCol: 'gray'
    // },
    benefits({ color: 'transparent', hasNavAnchor: true }),
    // estimate({}),
    kumo({ color: 'transparent' }),
    {
        id: 'Support',
        class: 'three-column-cards-w-image',
        title: 'The Support You Need',
        contentBoxes: [
            financing,
            warranty,
            {
                iconSrc: 'handshake',
                iconAlt: `${altText} call us for support`,
                blurb: 'Contact Us',
                detailsText:
                    "Have questions? Need support? Whether you're just doing research, or are ready to purchase, we are here to help. Reach out to our Customer Care team at:",
                phone: buPhone,
            },
        ],
        backgroundCol: 'blue',
    },
    subscribe({ campaignId: hero.subscriberCampaignId, color: 'gray' }),
]

const header = {
    pageTitle: "Stay cozy and save energy in the Northeast with Mitsubishi Electric's all-climate heat pumps",
    pageDesc:
        "Find year-round comfort with Mitsubishi Electric's single-zone and Hyper-Heating INVERTER® heat pump systems, where performance meets energy efficiency. Also take advantage of a $2,000 tax credit for a limited time!",
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: hero.ctaText,
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
