export const defaults = {
    hideContractorNumSelector: false,
    hideTimeframeSelector: false,
    hideContactMethodSelector: false,
    hideLocationSelectors: false,
    hideSystemSelector: false,
    hideCommunicationsSelector: false,
    hideDownArrow: false,
    amount: 0,
    leadCampaignId: '70131000001yHC6AAM', // comfort master
    subscriberCampaignId: '7013q0000020To2AAE', // comfort subscriber
    title: 'Designed for life, inside and out.',
    blurb: 'Find Utility Rebates Available In Your Area',
    backgroundSrc: '/images/residential_hero_bg.webp',
    detailsText:
        'Mitsubishi Electric systems are so efficient they qualify for rebates from many local electric utility providers. Enter your zip code for local rebates on qualifying heat pump systems installed by a licensed HVAC contractor.',
    formInstructions:
        'Fill out this form to request a consultation with a qualified HVAC contractor in your area. Up to three contractors may contact you to answer your questions and schedule an on-site consultation.',
    whatsNext: '',
    footnote:
        'Utility rebate is valid only within select areas and varies based on product purchased, purchase date, and other conditions. Please contact your local utility provider for more specific, detailed rebate information.',
    utilityProduct: 'MXZ-3C30',
}
