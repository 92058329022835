import { useState, useEffect } from 'react'
import { fetchLocalData } from '../lib/queries'
import Logo from './modular/Logo'
import SocialIcon from './modular/SocialIcon'
import '../styles/footer.css'

export default function Footer(props) {
    const [textColor, setTextColor] = useState('var(--text-col)')
    const [socialMedia, setSocialMedia] = useState([])

    useEffect(() => {
        props.darkMode && setTextColor('var(--white)')
        fetchLocalData('social-media.json').then((data) => setSocialMedia(data))
    }, [props.darkMode])

    return (
        <footer style={props.darkMode ? { background: `var(--bg-gradient-dk)` } : null}>
            <Logo branding={props.branding} darkMode={props.darkMode} />

            <div className="social-icons-row">
                {socialMedia.map((platform, i) => (
                    <SocialIcon key={i} href={platform.href} iconId={platform.iconId} color={props.darkMode && 'white'} />
                ))}
            </div>
            <div>
                <div className="footer-links-row">
                    <a href="https://www.mitsubishicomfort.com/" rel="noreferrer" target="_blank">
                        Visit Main Site
                    </a>
                    <a href="https://www.mitsubishicomfort.com/privacy-policy?policy-sections-tab=2" rel="noreferrer" target="_blank">
                        Terms of Use
                    </a>
                    <a href="https://www.mitsubishicomfort.com/privacy-policy" rel="noreferrer" target="_blank">
                        Privacy Policy
                    </a>
                    <a href="https://www.mitsubishicomfort.com/warranties" rel="noreferrer" target="_blank">
                        Warranty Statement
                    </a>
                    <a href="https://www.mitsubishicomfort.com/for-pros" rel="noreferrer" target="_blank">
                        Professionals
                    </a>
                    <div className="link ot-sdk-show-settings">Cookie Settings</div>
                </div>
                <p style={{ fontSize: 'smaller', color: textColor }}>
                    This site is protected by reCAPTCHA and the Google{' '}
                    <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
                        Terms of Service
                    </a>{' '}
                    apply.
                </p>
                <p id="copyright" style={{ color: textColor }}>
                    &copy;{new Date().getFullYear()} Mitsubishi Electric Trane HVAC US LLC
                </p>
            </div>
        </footer>
    )
}
