import { header, sections } from './page content/pageWbuContent'
import campaign from './page content/campaigns/colorado'
import Page from './Page'

export default function PageColorado() {
    const hero = {
        ...campaign(),
        scrollTo: sections[0].id,
    }

    return <Page header={header} hero={hero} sections={sections} />
}
