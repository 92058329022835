import { isCampaignRunning, isCampaignOver } from './isCampaign'
import { defaults } from '../components/page content/campaigns/_defaults'

export default function getCampaignData({
    bu,
    start,
    end,
    utilityProduct,
    submitFormBtnText,
    staticCampaign,
    noOutsideLeads,
    campaignAmount,
    defaultAmount,
    campaignId,
    defaultCampaignId,
    defaultSubscriberId,
    campaignHero,
    defaultHero,
    campaignCtaText,
    defaultCtaText,
    campaignImage,
    defaultImage,
    campaignImageAlign,
    defaultImageAlign,
    campaignVideoSrc,
    defaultVideoSrc,
    campaignTitle,
    defaultTitle,
    campaignBlurb,
    defaultBlurb,
    campaignDetails,
    defaultDetails,
    campaignFootnote,
    showFootnote,
    campaignWhatsNext,
    defaultWhatsNext,
    hideContractorNumSelector,
    hideTimeframeSelector,
    hideContactMethodSelector,
    hideLocationSelectors,
    hideSystemSelector,
    hideCommunicationsSelector,
    hideDownArrow,
}) {
    const startDate = new Date(start)
    const endDate = new Date(end)

    const branch = process.env.REACT_APP_BRANCH

    // make campaign-specific changes visible in staging b4 start date
    const isRunning = branch === 'STAGING' ? !isCampaignOver(endDate) : isCampaignRunning(startDate, endDate)

    const runningData = {
        hideContractorNumSelector,
        hideTimeframeSelector,
        hideContactMethodSelector,
        hideLocationSelectors,
        hideSystemSelector,
        hideCommunicationsSelector,
        hideDownArrow,
        amount: campaignAmount,
        leadCampaignId: campaignId,
        form: campaignHero,
        ctaText: campaignCtaText,
        whatsNext: campaignWhatsNext,
        backgroundSrc: campaignVideoSrc || campaignImage,
        imageAlign: campaignImageAlign,
        title: campaignTitle,
        blurb: campaignBlurb,
        detailsText: campaignDetails,
        staticCampaign: staticCampaign,
        noOutsideLeads: noOutsideLeads,
        footnote: (campaignAmount > 0 || showFootnote) && campaignFootnote,
    }

    const notRunningData = {
        hideContractorNumSelector: defaults.hideContractorNumSelector,
        hideTimeframeSelector: defaults.hideTimeframeSelector,
        hideContactMethodSelector: defaults.hideContactMethodSelector,
        hideLocationSelectors: defaults.hideLocationSelectors,
        hideSystemSelector: defaults.hideSystemSelector,
        hideCommunicationsSelector: defaults.hideCommunicationsSelector,
        hideDownArrow: defaults.hideDownArrow,
        amount: defaultAmount,
        leadCampaignId: defaultCampaignId,
        form: defaultHero,
        ctaText: defaultCtaText,
        whatsNext: defaultWhatsNext,
        backgroundSrc: defaultVideoSrc || defaultImage,
        imageAlign: defaultImageAlign,
        title: defaultTitle,
        blurb: defaultBlurb,
        detailsText: defaultDetails,
        footnote: showFootnote && campaignFootnote,
    }

    const finalData = {
        bu,
        startDate,
        endDate,
        isRunning,
        utilityProduct,
        subscriberCampaignId: defaultSubscriberId,
        submitFormBtnText: submitFormBtnText,
        ...(isRunning ? runningData : notRunningData),
    }

    // console.log(finalData)
    return finalData
}
