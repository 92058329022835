import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '7/1/2024'
    const end = '12/31/2024'
    const amount = 0

    const data = {
        // defaults
        bu: 'national',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: defaults.subscriberCampaignId,
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: defaults.backgroundSrc,
        defaultTitle: defaults.title,
        defaultBlurb: '',
        defaultDetails: defaults.detailsText,
        defaultFootnote: defaults.footnote,
        utilityProduct: defaults.utilityProduct,
        whatsNext: defaults.whatsNext,
        submitFormBtnText: null,
        // ME_Residential_National_Summer_2024_Performance Max_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        staticCampaign: true,
        campaignId: '701Pd00000CdVhmIAF',
        campaignHero: 'rebate',
        campaignCtaText: '',
        campaignImage: defaults.backgroundSrc,
        campaignTitle: defaults.title,
        campaignBlurb: '',
        campaignDetails: '',
        campaignFootnote: defaults.footnote,
        hideContractorNumSelector: false,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }
    return getCampaignData(data)
}
