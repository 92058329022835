export default function ira25C({ id = 'incentives', title = 'Up to $2,000 Federal Tax Credit', color = 'green', download = false, hasNavAnchor = false }) {
    return {
        id: id,
        class: 'two-column-img-right',
        hasNavAnchor: hasNavAnchor,
        title: title,
        imgSrc: '/images/lifestyle/interior/couple-doing-budget.webp',
        imgAlt: 'alt text',
        detailsText:
            'Under the Inflation Reduction Act*, homeowners are now eligible for a tax credit** of 30% of the cost, up to $2,000, for heat pumps, and/or heat pump water heaters, in accordance with section 25C of the US tax code. Only certain models qualify for the tax credit, and you must have a tax liability from which to reduce your taxes. Consult your tax advisor for advice.',
        iconList: {
            title: 'Benefits beyond savings',
            items: [
                { text: 'More energy efficient' },
                { text: 'No greenhouse gas emissions' },
                { text: 'Quieter than traditional HVAC systems' },
                { text: 'Individual room temperature control for personalized comfort' },
            ],
        },
        download: download && 'View list of qualifying products',
        footnote:
            '*The Inflation Reduction Act of 2022 (IRA) is the largest ever climate investment by the Federal Government in American history, projected to reduce greenhouse gas (GHG) by 31% to 44% below the 2005 levels by 2030.\n\n**Terms: Must be filed with taxes in 2025 if purchased during the 2024 tax year. Any combination of heat pumps, heat pump water heaters and biomass stoves/boilers are subject to an annual total limit of $2,000. Can be combined with other utility rebates. Federal Tax Credit IRS Form. Must be an existing home and principal residence. New construction and rentals do not apply. Principal residence can be a house, houseboat, mobile home, cooperative apartment, condominium, or a manufactured home. The overall total limit for an efficiency tax credit in one year is $3,200. Total limit of $1,200 for any combination of home envelope improvements (windows/doors/skylights, insulation, electrical) plus furnaces, boilers and central air conditioners.',
        backgroundCol: color,
    }
}
