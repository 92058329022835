import altText from './altText'

export default function intelliHeat({ id = 'intelli-HEAT', hasNavAnchor = false, title = 'Welcome to Hybrid HVAC', color = 'transparent' }) {
    return {
        id: id,
        class: 'two-column-img-left',
        hasNavAnchor: hasNavAnchor,
        title: title,
        blurb: 'Smart, Eco-efficient Comfort',
        detailsText: `Mitsubishi Electric’s intelli-HEAT dual fuel system consists of 2 units: an exterior heat pump, which replaces your existing air-conditioning condenser, and an interior unit that is installed on your existing gas furnace*. This smart system not only improves air conditioning efficiency on hot days, but also determines the best source of heat (gas or electricity) on cold days, so your HVAC system is always running at peak efficiency and comfort.`,
        imgSrc: '/images/product/intelli-HEAT-on-furnace.webp',
        imgAlt: `${altText} intelli-HEAT hybrid HVAC a-coil attaches to your existing gas furnace`,
        ctaText: 'Watch Video',
        videoSrc: 'U54nJnoVmy4',
        footnote: '*Mitsubishi Electric air conditioner and heat pump systems should only be connected with ANSI-Z21.47/CSA2.3 certified furnaces.',
        backgroundCol: color,
    }
}
