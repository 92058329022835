// const formatModelNo = (model) => {
//   const newModel = model.replace(/\.|\(|\)/g , '').replace(/-/g, '_')
//   return newModel
// }

const imgPath = '/images/product/'
const wallUnitImg = imgPath + 'wall-mounted-outline.webp'
const ceilingOneWayUnitImg = imgPath + 'ceiling-cassette-outline.webp'
const ceilingFourWayUnitImg = imgPath + 'ceiling-4-way-outline.webp'
const airHandlerUnitImg = imgPath + 'air-handler-outline.webp'
const horizontalDuctedUnitImg = imgPath + 'horizontal-ducted-outline.webp'
const ceilingSuspendedUnitImg = imgPath + 'ceiling-suspended-outline.webp'
const floorUnitImg = imgPath + 'floor-mounted-outline.webp'
const intelliHeatImg = imgPath + 'intelli-HEAT_200px.webp'

export const indoorUnitButtons = [
    { label: 'wall', imgSrc: wallUnitImg, order: 1 },
    { label: 'ceiling (1-way)', imgSrc: ceilingOneWayUnitImg, order: 2 },
    { label: 'air handler', imgSrc: airHandlerUnitImg, order: 3 },
    { label: 'dual fuel cased coil', imgSrc: intelliHeatImg, order: 8 },
    { label: 'floor', imgSrc: floorUnitImg, order: 4 },
    { label: 'ceiling (4-way)', imgSrc: ceilingFourWayUnitImg, order: 5 },
    { label: 'ceiling suspended', imgSrc: ceilingSuspendedUnitImg, order: 6 },
    { label: 'ducted', imgSrc: horizontalDuctedUnitImg, order: 7 },
]

export const handleDucted = (value) => {
    if (value === 'ducted') return 'horizontal ducted'
    return value
}

export const processOutdoorUnits = (units) => {
    if (!units || units.length === 0) {
        return []
    }

    units.forEach((unit) => {
        // unit.heating_type === 'Cooling only' ? unit.heating = false : unit.heating = true
        unit = unit.attributes
        if (unit.heating_type === 'Cooling only') {
            unit.heating_min = 'n/a'
            unit.heating_max = 'n/a'
            unit.heating_restart = 'n/a'
            unit.heating_thermal_lockout = 'n/a'
            unit.cooling_restart = 'n/a'
            unit.cooling_thermal_lockout = 'n/a'
        }
        unit.capacity = unit.cu_size
        unit.capacity_outdoor = unit.cu_size
        // generate array of titles and images for indoor units
        unit.compatible_indoor_unit_types = []
        const pushUnit = (i) => unit.compatible_indoor_unit_types.push(indoorUnitButtons[i])
        unit.wall && pushUnit(0)
        unit.floor && pushUnit(4)
        unit.ducted && pushUnit(7)
        unit.air_handler && pushUnit(2)
        unit.ceiling_suspended && pushUnit(6)
        unit.ceiling_one_way && pushUnit(1)
        unit.ceiling_four_way && pushUnit(5)
        unit.dual_fuel_cased_coil && pushUnit(3)
    })
    return units
}
