import { allProducts } from './allProducts'

export default function indoorSolutions({
    ctaText = 'Learn More',
    color = 'transparent',
    hasNavAnchor = false,
    id = 'indoor-solutions',
    products = ['wall-mounted', 'ceiling-cassette', 'intelli-heat', 'air-handler'],
    title = 'Indoor Solutions',
}) {
    const columns = getProducts(products)

    return {
        id: id,
        class: 'four-column',
        imgBg: true,
        hasNavAnchor: hasNavAnchor,
        title: title,
        ctaText: ctaText,
        backgroundCol: color,
        columns: columns,
    }
}

function getProducts(productIds) {
    return productIds.map((productId) => {
        return allProducts.find((product) => product.id === productId)
    })
}
