import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '3/4/2024'
    const end = '5/31/2024'
    const amount = 0

    const data = {
        // defaults
        bu: 'swbu',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000001kmYXAAY',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/interior/swbu-hero.webp',
        defaultTitle: defaults.title,
        defaultBlurb: '',
        defaultDetails:
            "Mitsubishi Electric's technologically advanced mini-split air conditioners and heat pumps can provide superior comfort, lower energy costs, zero emissions, and ultra-quiet heating and air conditioning in any climate. Learn more about our home heating and cooling solutions below.",
        defaultFootnote: defaults.footnote,
        utilityProduct: defaults.utilityProduct,
        whatsNext: '1-855-947-1544',
        submitFormBtnText: null,
        // ME_Residential_SWBU_Spring_2024_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        campaignId: '701Pd000005u1csIAA',
        campaignHero: 'rebate',
        campaignCtaText: '',
        campaignImage: '/images/SWBU_hero_spring_2024.webp',
        campaignTitle: 'When design meets perfection',
        campaignBlurb: '',
        campaignDetails: `Our all-climate heat pumps are designed for energy-efficiency and flexibility to solve your heating and cooling needs. Our systems are designed to fit any application, delivering personalized temperature control for one room or a whole home, with or without ducts. Read on to discover how to elevate your home's comfort.`,
        // campaignDetails: 'Mitsubishi Electric’s high-efficiency heat pumps and comfort solutions can provide superior indoor air quality, lower energy costs, zero emissions, and ultra-quiet heating and air conditioning in any climate, ensuring all of your moments will be something special. Learn more about our home heating and cooling solutions below.',
        campaignFootnote: `Instant rebate of $${amount} is valid only within select areas in New Mexico and varies based on system purchased. Qualifying system must be purchased from and installed by an eligible contractor in an owner-occupied residence between ${start} and ${end}.`,
        hideContractorNumSelector: false,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}
