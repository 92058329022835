import campaign from './campaigns/generator'
import altText from './static-content/altText'
import subscribe from './static-content/subscribe'
import indoorSolutions from './static-content/indoorSolutions'
import { defaults } from './campaigns/_defaults'
import ira25C from './static-content/ira-25c'

const buPhone = '855-947-1544'
const ctaText = 'Get Started'

const hero = {
    ...campaign(),
    formInstructions: defaults.formInstructions,
}

const sections = [
    {
        id: 'Proactive',
        class: 'two-column-img-right',
        hasNavAnchor: true,
        title: 'Get Ready for Storm Season',
        detailsText:
            "Designed for life, inside and out, our high-efficiency heat pumps are here to give you peace of mind during storm season. In case of electricity outages, you can easily switch over your heating or cooling from your home's electrical system to a generator or solar energy, keeping you comfortable while the storm rages on.",
        imgSrc: '/images/texas-storm.webp',
        imgAlt: `${altText} video poster for Mitsubishi Electric HVAC video "be proactive, not reactive"`,
        ctaText: 'Watch Video',
        videoSrc: 'et4-ZZDoEqg',
    },
    {
        id: 'Prepared',
        class: 'two-column-img-left',
        title: '',
        hasNavAnchor: true,
        blurb: 'Find Your Perfect Preparedness Pair',
        detailsText:
            'Our highly efficient, all-electric heat pumps use very little energy to heat or cool. Unlike traditional systems, our electric heat pumps systems can be switched to run on solar, batteries, or generators when your home has a power outage.',
        imgSrc: '/images/generator_house_2022_final.svg',
        imgAlt: `Mitsubishi Electric's energy efficient, all-electric, Mini Split Heat Pump systems can provide indoor heating and air conditioning while powered by a generator.`,
        ctaText: 'Get Started',
        ctaUrl: 'form-section',
    },
    ira25C({ hasNavAnchor: true }),
    {
        id: 'Efficiency',
        class: 'one-column-image',
        title: '',
        blurb: 'Up to 40% More Efficient Than Conventional Systems',
        detailsText:
            'Mitsubishi Electric systems are built with a variable-capacity inverter, which utilizes low amounts of electricity and zero fossil fuels.',
        imgSrc: '/images/energy-graph-light.jpg',
        imgAlt: `${altText} graph illustrating efficiency versus conventional systems`,
        backgroundCol: 'site',
    },
    indoorSolutions({}),
    // {
    // 	id: 'Support',
    // 	class: 'three-column-cards-w-image',
    // 	title: 'The Support You Need',
    // 	contentBoxes: [
    // 	  financing,
    // 	  warranty,
    // 		{
    // 			iconSrc: 'handshake',
    // 			iconAlt: `${altText} call us for support`,
    // 			blurb: 'Contact Us',
    // 			detailsText: 'Have questions? Reach out to our Customer Care team at',
    // 			phone: buPhone
    // 		},
    // 	],
    // 	backgroundCol: 'accent',
    // },
    subscribe({ campaignId: hero.subscriberCampaignId }),
]

const header = {
    pageTitle: 'Be Ready for Unpredictable Weather with Generator-friendly HVAC Systems',
    pageDesc: "Mitsubishi Electric's high-efficiency heat pumps can be powered by a home generator or battery system when the power is out.",
    pageImg: '/images/generator_house_2022_final.svg',
    phone: buPhone,
    ctaText: ctaText,
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
